body {
  background-color: lightgray;
}

.App {
  text-align: center;
}

div.numbrix-status {
  margin-bottom: 20px;
}

p.status-msg {
  padding: 5px 0 5px;
  background-color: yellow;
}

.numbrix-cell, .numbrix-cell-error, .numbrix-cell-constant {
  height: 50px;
  width: 50px;
  text-align: center;
}

button.status-btn {
  margin: 0px 10px 10px 10px;
  padding: 5px;
}

.numbrix-cell:hover {
  background-color: lightblue;
}

.numbrix-cell-error {
  background-color: lightpink;
}

.numbrix-cell-error:hover {
  background-color: #dd6666;
}

.numbrix-cell-constant {
  font-weight: bold;
}